<template>
    <!-- <v-card tile flat class="detail-about-company-main-bloc fill-height rounded-lg" height="auto" v-if="company != null"> -->

    <v-card flat tile class="my-2 pa-2">
        <div class="detail-about-company-main-bloc" v-if="company != null">
            <div class="pb-4 d-flex flex-row flex-nowrap">
                <h4>About company</h4>
                <v-spacer></v-spacer>
                <v-btn small text color="secondary" class="px-4 subsecondary rounded-lg" outlined
                    @click="viewsCompanyDetails">
                    <span class="mr-2"> View more</span>
                    <v-icon small>
                        {{ icons.mdiOpenInNew }}
                    </v-icon>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <div class="d-flex flex-row py-4">
                <v-avatar size="50">
                    <v-img src="@/assets/images/avatars/img-xl.svg" height="100%"></v-img>
                </v-avatar>
                <div class="d-flex flex-column ml-2 ">
                    <h4 class="cc-text">{{ company.name }}</h4>
                    <p class="d-flex flex-wrap flex-row text-caption">
                        <span class="my-auto mb-1">{{ company.type !== null ? company.type.designation : ''
                        }}</span>
                    </p>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex flex-column text-caption">
                    <v-rating :value="4.5" color="primary" class="pa-0" dense half-increments readonly size="20">
                    </v-rating>
                    <div class="text-caption">4.5 (413)</div>
                </div>
            </div>
            <div class="mx-1 rounded-lg">
                <!-- <h3 class="cctext-hove">Sector</h3>
                <p class="cctext-hove text-caption"></p> -->
                <h4 class="cctext-hove">About</h4>
                <p class="cctext-hove text-caption">{{ company.about }}</p>
                <h4 class="cc-text d-flex flex-wrap flex-row align-center">
                    <v-icon small>{{ icons.mdiWeb }}</v-icon>
                    <span class="my-auto ml-1 mb-1 caption">{{ company.website == null ? "Unnamed" :
                            company.website
                    }}</span>
                </h4>
            </div>
        </div>
    </v-card>
</template>


<script>
// eslint-disable-next-line object-curly-newline
import { mdiMapMarkerOutline, mdiWeb, mdiOpenInNew } from '@mdi/js'
import { ref } from '@vue/composition-api'
var md5 = require("md5")

export default {
    props: {
        company: {
            type: Object,
            default: null
        }
    },
    methods: {
        viewsCompanyDetails() {
            this.$router.push({ name: "company.views", params: { f_id: md5(this.company.id), ids: this.company.id } })
        }
    },
    setup(props) {
        const icons = { mdiMapMarkerOutline, mdiWeb, mdiOpenInNew }

        return {
            icons
        }
    },
}
</script>

<style lang="scss">
// @import '~@/assets/css/style.scss';

.cc-text {
    color: var(--v-secondaryAccent-base);
}

.cc-text-hove {
    color: var(--v-accent-base);
}

.detail-about-company-main-bloc {
    position: relative;
    //border: solid 1px rgb(236, 235, 236) !important;
    height: 100%;
    border-radius: 0.6em !important;
    padding: 1em;
}
</style>
